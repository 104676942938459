import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.name) {
        errors.name = 'Site name is required'
    }

    if(!values.shippingAddress.street1) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.street1 = 'Street is required'
    }

    if(!values.shippingAddress.city) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.city = 'City is required'
    }

    if(!values.shippingAddress.state) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.state = 'State is required'
    }

    if(!values.shippingAddress.zipcode) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.zipcode = 'Zipcode is required'
    }

    if(!values.shippingAddress.country) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.country = 'Country is required'
    }

    if(!values.billingAddress.street1) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.street1 = 'Street is required'
    }

    if(!values.billingAddress.city) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.city = 'City is required'
    }

    if(!values.billingAddress.state) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.state = 'State is required'
    }

    if(!values.billingAddress.zipcode) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.zipcode = 'Zipcode is required'
    }

    if(!values.billingAddress.country) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.country = 'Country is required'
    }

    if(values.primaryContact && values.primaryContact.emailAddress && !EmailValidator.validate(values.primaryContact.emailAddress)){

        errors.emailAddress = 'Valid email address required'
    }

    if (values.timeCodes) {
        errors.timeCodes = 'Empty or invalid values in time codes'
    }

    return errors;
}
